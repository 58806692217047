import { useState, useCallback, useRef, useEffect } from 'react';

import {
  Grid,
  GridItem,
  Section,
  useViewport,
  useIntersection,
} from '@farmersdog/corgi-x';
import { DotLottieReact, type DotLottie } from '@lottiefiles/dotlottie-react';
import CXLottieJson from './assets/cx.json';
import {
  ExtendedButton,
  ExtendedText,
} from 'src/pages/HomepageRedesign/components';

import { useHasScrolled } from 'src/hooks/useHasScrolled';

import styles from './CXBlock.module.css';

const MOBILE_QUOTE = (
  <>
    “The Farmer’s Dog staff
    <br />
    is remarkable. Everyone
    <br />
    has impressed me with
    <br />
    their care, consideration,
    <br />
    and willingness to
    <br />
    address my concerns.”
  </>
);

const DESKTOP_QUOTE = (
  <>
    “The Farmer’s Dog staff is remarkable. Everyone has
    <br className={styles.desktop} />
    impressed me with their care, consideration, and
    <br className={styles.desktop} />
    willingness to address my concerns.”
  </>
);

const MOBILE_SUBTITLE = (
  <>
    If you have any questions or concerns,
    <br />
    our Customer Service Team is here to
    <br />
    support you 24/7.
  </>
);

const DESKTOP_SUBTITLE = (
  <>
    If you have any questions or concerns, our Customer Service Team
    <br />
    is here to support you 24/7.
  </>
);

export const CXBlock = () => {
  const isMobile = !useViewport().md;
  const hasScrolled = useHasScrolled();

  const [lottiePlayer, setLottiePlayer] = useState<DotLottie | null>(null);
  const lottieWrapperRef = useRef<HTMLDivElement>(null);
  const [hasPlayed, setHasPlayed] = useState(false);

  const intersection = useIntersection(lottieWrapperRef.current, {
    threshold: 0.8,
  });

  const lottieRefCallback = useCallback((player: DotLottie | null) => {
    setLottiePlayer(player);
  }, []);

  useEffect(() => {
    if (intersection?.isIntersecting && !hasPlayed) {
      lottiePlayer?.play();
      setHasPlayed(true);
    }
  }, [intersection?.isIntersecting, lottiePlayer, hasPlayed]);

  return (
    <div ref={lottieWrapperRef} className={styles.wrapper}>
      <Section
        variant="card"
        aria-labelledby="placeholder"
        className={styles.card}
      >
        {hasScrolled && (
          <DotLottieReact
            dotLottieRefCallback={lottieRefCallback}
            data={CXLottieJson}
            autoplay={false}
            loop={false}
            aria-hidden="true"
            className={styles.lottie}
          />
        )}
        <ExtendedText
          as="p"
          color="Kale3"
          topSpacing="sm"
          bottomSpacing="none"
          variant={isMobile ? 'article-24' : 'article-28'}
        >
          {isMobile ? MOBILE_QUOTE : DESKTOP_QUOTE}
        </ExtendedText>
        <ExtendedText
          as="p"
          topSpacing={{ xs: 'md', lg: 'sm' }}
          bottomSpacing={{ xs: 'md', lg: 'lg' }}
          color="Kale3"
          weight="bold"
          variant="heading-18"
        >
          — Opie’s Person
        </ExtendedText>
        <ExtendedText
          as="p"
          fontStyle="italic"
          weight="semibold"
          color="Kale3"
          variant="heading-16"
          topSpacing="none"
          bottomSpacing={{ xs: 'md', lg: 'lg' }}
        >
          {isMobile ? MOBILE_SUBTITLE : DESKTOP_SUBTITLE}
        </ExtendedText>

        {isMobile ? (
          <Grid gap="xs">
            <GridItem xs={6}>
              <ExtendedButton
                type="anchor"
                href="sms:+16467807957"
                className={styles.button}
                variant="solid-kale-3"
              >
                Text Us
              </ExtendedButton>
            </GridItem>
            <GridItem xs={6}>
              <ExtendedButton
                type="anchor"
                href="tel:+16467807957"
                className={styles.button}
                variant="bordered-kale"
              >
                Call Us
              </ExtendedButton>
            </GridItem>
          </Grid>
        ) : (
          <ExtendedButton
            type="anchor"
            variant="bordered-kale"
            href="mailto:help@thefarmersdog.com"
          >
            Email Us
          </ExtendedButton>
        )}
      </Section>
    </div>
  );
};
