import { useMemo } from 'react';

import { EMAIL_HELP } from '@farmersdog/constants/emails';
import { FRESH_CX_PHONE } from '@farmersdog/constants/phoneNumbers';
import { Grid, GridItem, Section, useViewport } from '@farmersdog/corgi-x';

import { ExtendedButton } from '../ExtendedButton';
import { ExtendedText } from '../ExtendedText';
import deviceType from '../../../../utils/deviceType';

import styles from './ContactUs.module.css';
import { CtaTrackerV2, HomepageRedesignSections } from '../CtaTrackerV2';
import { useTrackedSectionRef } from '../../../../hooks/useTrackedSections';

export function ContactUs() {
  const isMobile = !useViewport().md;
  const isTouchable = deviceType.touchable;
  const contactUsCopy = useMemo(() => {
    return isMobile ? (
      <ExtendedText variant="article-24" color="kale-3">
        Real humans who love <br />
        your dog are here to support <br />
        you anytime.
      </ExtendedText>
    ) : (
      <ExtendedText variant="article-28" color="kale-3">
        Real humans who love your dog are here to support you. Call us at{' '}
        {FRESH_CX_PHONE.display}.
      </ExtendedText>
    );
  }, [isMobile]);

  const buttons = useMemo(() => {
    return {
      primary: {
        copy: isTouchable ? 'Text Us' : 'Email Us',
        link: isTouchable
          ? `sms:${FRESH_CX_PHONE.link}`
          : `mailto:${EMAIL_HELP}`,
      },
      secondary: {
        link: `tel:${FRESH_CX_PHONE.link}`,
        copy: 'Call Us',
      },
    };
  }, [isTouchable]);

  const sectionRef = useTrackedSectionRef<HTMLDivElement>(
    HomepageRedesignSections.ContactUs
  );

  return (
    <Section
      aria-label="contact us"
      hSpacing="md"
      className={styles.wrapper}
      sectionRef={sectionRef}
    >
      <Grid gap="lg" justifyContent="center">
        <GridItem>{contactUsCopy}</GridItem>
        <GridItem className={styles.buttonsContainer}>
          <CtaTrackerV2
            type="select"
            moduleLevel={1}
            moduleName={HomepageRedesignSections.ContactUs}
          >
            <ExtendedButton
              type="anchor"
              href={buttons.primary.link}
              className={styles.button}
              variant="solid-kale-2"
            >
              {buttons.primary.copy}
            </ExtendedButton>
          </CtaTrackerV2>
          {isMobile && (
            <CtaTrackerV2
              type="select"
              moduleLevel={1}
              moduleName={HomepageRedesignSections.ContactUs}
            >
              <ExtendedButton
                type="anchor"
                href={buttons.secondary.link}
                className={styles.button}
                variant="bordered-kale"
              >
                {buttons.secondary.copy}
              </ExtendedButton>
            </CtaTrackerV2>
          )}
        </GridItem>
      </Grid>
    </Section>
  );
}
