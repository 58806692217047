import type React from 'react';
import { useState } from 'react';
import {
  Grid,
  GridItem,
  Nowrap,
  PageWidth,
  Picture,
  Text,
  useViewport,
  generateSourcesForPicture,
  type MobileAndDesktopSources,
} from '@farmersdog/corgi-x';
import { PATH_SIGNUP_ME, PATH_REVIEWS } from '@farmersdog/constants/paths';
import { getLinkToFreshSignup } from '@farmersdog/tosa/src/utils/getLinkToSignupFlow';

import {
  ExtendedButton,
  ExtendedGallery,
  ExtendedText,
  ExtendedVideoSection,
  Quote,
} from '../../components';

import styles from './StoriesFromSection.module.css';
import {
  luluDesktopjpg,
  luluDesktopWebp,
  luluMobilejpg,
  luluMobileWebp,
  konavandiDesktopjpg,
  konavandiDesktopWebp,
  konavandiMobilejpg,
  konavandiMobileWebp,
  kimchiDesktopjpg,
  kimchiDesktopWebp,
  kimchiMobilejpg,
  kimchiMobileWebp,
  berneDesktopjpg,
  berneDesktopWebp,
  berneMobilejpg,
  berneMobileWebp,
  zboDesktopWebp,
  zboMobileWebp,
  zboDesktopjpg,
  zboMobilejpg,
} from './assets';
import { CtaTrackerV2, HomepageRedesignSections } from '../CtaTrackerV2';
import { useTrackedSectionRef } from '../../../../hooks/useTrackedSections';

type WistiaIds = {
  mobile: string;
  desktop: string;
};

type VideoGalleryItem = {
  id: number;
  label: string;
  wistiaId: WistiaIds;
  sources: MobileAndDesktopSources;
  alt: string;
  title: string;
  quote: string | React.ReactNode;
};

const videoItemsData: VideoGalleryItem[] = [
  {
    id: 1,
    label: 'Senior Dog',
    wistiaId: {
      mobile: 'hcxjxcav2r',
      desktop: 'a8khj5kmi1',
    },
    sources: {
      mobileSources: { webP: luluMobileWebp, jpg: luluMobilejpg },
      desktopSources: { webP: luluDesktopWebp, jpg: luluDesktopjpg },
    },
    alt: 'Meet Lulu',
    title: 'MEET LULU',
    quote: (
      <>
        Lulu, 22 years old, took to <Nowrap>The Farmer’s Dog</Nowrap>{' '}
        immediately. We actually saw some really nice improvements in{' '}
        <Nowrap>her energy.</Nowrap>
      </>
    ),
  },
  {
    id: 2,
    label: 'Weight Management',
    wistiaId: {
      mobile: '8va1aw7zfa',
      desktop: '6nd3nm6v5j',
    },
    sources: {
      mobileSources: { webP: konavandiMobileWebp, jpg: konavandiMobilejpg },
      desktopSources: { webP: konavandiDesktopWebp, jpg: konavandiDesktopjpg },
    },
    alt: 'Meet Konavandi',
    title: 'MEET KONA & VANDI',
    quote: (
      <>
        After a month, Kona started to perk up a lot more, and he lost weight.
        Seeing that spark in him again was <Nowrap>super exciting.</Nowrap>
      </>
    ),
  },
  {
    id: 3,
    label: 'Picky Eater',
    wistiaId: {
      mobile: '9ff5zi4cf8',
      desktop: 'rlnbe55fgu',
    },
    sources: {
      mobileSources: { webP: kimchiMobileWebp, jpg: kimchiMobilejpg },
      desktopSources: { webP: kimchiDesktopWebp, jpg: kimchiDesktopjpg },
    },
    alt: 'Meet Kimchi',
    title: 'MEET KIMCHI',
    quote: (
      <>
        When we fed her <Nowrap>The Farmer’s Dog</Nowrap> food, Kimchi got
        excited about eating in a way that she wasn’t with any{' '}
        <Nowrap>other food.</Nowrap>
      </>
    ),
  },
  {
    id: 4,
    label: 'Longevity',
    wistiaId: {
      mobile: '59yjjo64lz',
      desktop: 'l8uqz3wr0b',
    },
    sources: {
      mobileSources: { webP: berneMobileWebp, jpg: berneMobilejpg },
      desktopSources: { webP: berneDesktopWebp, jpg: berneDesktopjpg },
    },
    alt: 'Meet Berne & Ursula',
    title: 'MEET BERNE & URSULA',
    quote: (
      <>
        Berne’s much more youthful compared to dogs his own age. He’s in good
        shape and he’s <Nowrap>always active.</Nowrap>
      </>
    ),
  },
  {
    id: 5,
    label: 'Picky Eater',
    wistiaId: {
      mobile: '0wsrcihu9q',
      desktop: 'an6xo8c5hu',
    },
    sources: {
      mobileSources: { webP: zboMobileWebp, jpg: zboMobilejpg },
      desktopSources: { webP: zboDesktopWebp, jpg: zboDesktopjpg },
    },
    alt: 'Meet ZBO',
    title: 'MEET ZBO',
    quote: (
      <>
        We changed food every week before starting{' '}
        <Nowrap>The Farmer’s Dog.</Nowrap> Best part is ZBO seems healthier and
        he likes <Nowrap>the taste!</Nowrap>
      </>
    ),
  },
];

export const StoriesFromSection = () => {
  const linkToFreshSignup = getLinkToFreshSignup(PATH_SIGNUP_ME);
  const [activeItem, setActiveItem] = useState(1);

  const viewport = useViewport();
  const isMobile = viewport.xs && !viewport.md;
  const safeActiveItem = Math.max(
    0,
    Math.min(activeItem, videoItemsData.length - 1)
  );

  const sectionRef = useTrackedSectionRef<HTMLDivElement>(
    HomepageRedesignSections.Testimonials
  );

  const handleSlideChange = (index: number) => {
    setActiveItem(index);
  };

  return (
    <div className={styles.wrapper} ref={sectionRef}>
      <section
        aria-labelledby="video-gallery-section"
        className={styles.container}
      >
        <Grid
          gap={{
            xs: 'md',
            lg: 'lg',
          }}
        >
          <GridItem>
            <Text
              color="Kale3"
              variant={isMobile ? 'heading-28' : 'heading-40'}
              bold
              id="video-gallery-section"
            >
              Stories from {isMobile && <br />}
              over 1 billion meals
            </Text>
          </GridItem>
          <ExtendedGallery
            id="video-gallery"
            label="Video Gallery"
            slideClassname={styles.slide}
            onSlideChange={handleSlideChange}
            className={styles.gallery}
            isWide={!isMobile}
            activeIndex={safeActiveItem}
            withDots={true}
            mode="darker"
            footnote={
              <PageWidth
                className={styles.slideTextContainer}
                key={safeActiveItem}
              >
                <ExtendedText
                  variant="spaced"
                  weight="semibold"
                  color="kale-3"
                  as="h3"
                  topSpacing={{
                    xs: 'md',
                    lg: 'lg',
                  }}
                  bottomSpacing="sm"
                >
                  {videoItemsData[safeActiveItem].title}
                </ExtendedText>
                <Quote text={videoItemsData[safeActiveItem].quote} />
              </PageWidth>
            }
          >
            {videoItemsData.map((item, index) => {
              const isCurrent = safeActiveItem === index;

              const wistiaId = isMobile
                ? item.wistiaId.mobile
                : item.wistiaId.desktop;

              const sources = generateSourcesForPicture(item.sources);

              return (
                <ExtendedVideoSection
                  wistiaOptions={{
                    autoPlay: false,
                    playsinline: true,
                    endVideoBehavior: 'reset',
                    playPauseNotifier: false,
                    videoFoam: true,
                    playButton: false,
                  }}
                  key={`${item.id}-${index}`}
                  wistiaId={wistiaId}
                  showOverlayContent={!isCurrent}
                  activeSlideInGallery={isCurrent}
                  videoTitle={item.label}
                  className={styles.videoContainer}
                  overlayContent={
                    <Picture sources={sources} loading="lazy" alt={item.alt} />
                  }
                  moduleName={HomepageRedesignSections.Testimonials}
                />
              );
            })}
          </ExtendedGallery>
          <GridItem
            flexDirection={isMobile ? 'column' : 'row'}
            justifyContent="center"
            alignItems="center"
            className={styles.buttonsContainer}
          >
            <CtaTrackerV2
              type="select"
              moduleLevel={1}
              moduleName={HomepageRedesignSections.Testimonials}
            >
              <ExtendedButton
                variant="solid-kale-2"
                type="link"
                to={linkToFreshSignup}
                className={styles.button}
              >
                Start Your Journey
              </ExtendedButton>
            </CtaTrackerV2>
            <CtaTrackerV2
              type="select"
              moduleLevel={1}
              moduleName={HomepageRedesignSections.Testimonials}
            >
              <ExtendedButton
                variant="bordered-kale"
                type="link"
                to={PATH_REVIEWS}
                className={styles.button}
              >
                See More Stories
              </ExtendedButton>
            </CtaTrackerV2>
          </GridItem>
        </Grid>
      </section>
    </div>
  );
};
