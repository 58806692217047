import { getCentsFromDollars } from './getCentsFromDollars';

interface SubscribeAndSaveQuoteValues {
  subscribeAndSaveOrderSubtotal: number;
  subscribeAndSaveDiscount: number;
  orderDiscountDollarAmount: number;
  treatsDiscountDollarAmount: number;
  totalDiscountSum: number;
}

type GetSubscribeAndSaveQuoteValuesProps = SubscribeAndSaveQuoteValues | null;

export function getSubscribeAndSaveQuoteValues(
  subscribeAndSaveQuote: GetSubscribeAndSaveQuoteValuesProps
) {
  if (subscribeAndSaveQuote) {
    return {
      subscribeAndSaveOrderSubtotal: getCentsFromDollars(
        subscribeAndSaveQuote.subscribeAndSaveOrderSubtotal
      ),
      subscribeAndSaveDiscount: getCentsFromDollars(
        subscribeAndSaveQuote.subscribeAndSaveDiscount
      ),
      orderDiscountAmount: getCentsFromDollars(
        subscribeAndSaveQuote.orderDiscountDollarAmount
      ),
      treatsDiscountAmount: getCentsFromDollars(
        subscribeAndSaveQuote.treatsDiscountDollarAmount
      ),
      totalDiscountSum: getCentsFromDollars(
        subscribeAndSaveQuote.totalDiscountSum
      ),
    };
  } else {
    return {
      subscribeAndSaveOrderSubtotal: undefined,
      subscribeAndSaveDiscount: undefined,
      orderDiscountAmount: undefined,
      treatsDiscountAmount: undefined,
      totalDiscountSum: undefined,
    };
  }
}
